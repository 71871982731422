import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/1.jpg';
// import blogd2 from '../../assets/images/14.jpg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  School of Nursing Examinations
                </h2>
                <p className='blog-one__text'>
                  The Entrance Exam into College of Nursing, LUTH will hold on
                  <strong>Saturday, 09/09/23 at DLI, Unilag by 7:00 am</strong>.
                  Students are expected to please come with the following to the
                  examination hall; 
                  <br/>
                  1. Your Remita receipt.
                  <br/> 
                  2. Print out Bio-data.
                  <br/>
                  3. Means of identification.
                  <br />
                  {/* <img src={blogd2}></img> */}
                  <br />
                </p>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>Schools Admin</a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Schools Application Open | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
